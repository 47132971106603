import React from "react";
import { graphql, Link } from "gatsby";
import { mapEdgesToNodes } from "../lib/helpers";
import SEO from "../components/seo";
import Sidebar from "../components/Sidebar";
import Layout from "../containers/layout";
import SingleRowVertical from "../components/post-previews/SingleRowVertical";
import {
  PageTitle,
  PageDivider,
  MaxWidth,
  FlexRow,
  Col66,
  Col33,
  SmallDivider,
  SmallText,
} from "../components/ui-components";

export const query = graphql`
  query LatestPageQuery {
    newestPosts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      limit: 10
    ) {
      edges {
        node {
          id
          publishedAt(formatString: "MMM DD, YYYY")
          mainImage {
            asset {
              fluid(maxWidth: 768) {
                ...GatsbySanityImageFluid
              }
            }
          }
          authors {
            author {
              id
              name
              image {
                asset {
                  fixed(width: 36) {
                    ...GatsbySanityImageFixed_noBase64
                  }
                }
              }
              slug {
                current
              }
            }
          }
          title
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          _rawBody(resolveReferences: { maxDepth: 5 })
          slug {
            current
          }
        }
      }
    }
    allSanitySiteSettings {
      nodes {
        contactInfo {
          email
          facebook
          instagram
          twitter
        }
        description
        title
      }
    }
    allSanityAuthor {
      nodes {
        image {
          asset {
            fixed(width: 40) {
              ...GatsbySanityImageFixed
            }
          }
        }
        id
        name
        slug {
          current
        }
      }
    }
    allSanityBlogPostTag {
      nodes {
        tagName
        slug {
          current
        }
        id
      }
    }
  }
`;

const LatestPage = (props) => {
  const { data, location } = props;

  const newestPostNodes = data && data.newestPosts && mapEdgesToNodes(data.newestPosts);

  const NewestPosts = (
    <SingleRowVertical
      posts={newestPostNodes}
      authorTop={true}
      readMoreLink={true}
      className={"border-bottom"}
    />
  );

  return (
    <Layout location={location.pathname}>
      <SEO title="Archive" />
      <>
        <MaxWidth>
          <PageTitle>Latest Posts</PageTitle>
        </MaxWidth>
        <PageDivider />
        <MaxWidth>
          <FlexRow>
            <Col66>
              <p style={{ marginBottom: 0, color: "0,0,0,0.84" }}>
                The latest stories published by Inside Cal Poly
              </p>
              <SmallDivider />
              {newestPostNodes && newestPostNodes.length > 0 && NewestPosts}

              <SmallText>
                These are the latest stories published by Inside Cal Poly; you can also dive into
                the <Link to={"/archive/"}>archives</Link>.
              </SmallText>
            </Col66>
            <Col33>
              <Sidebar
                settings={data.allSanitySiteSettings.nodes[0]}
                tags={data.allSanityBlogPostTag.nodes}
                authors={data.allSanityAuthor.nodes}
              />
            </Col33>
          </FlexRow>
        </MaxWidth>
      </>
    </Layout>
  );
};

export default LatestPage;
